import { Component } from "@angular/core";

import { Platform } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
// import { BackgroundMode } from '@ionic-native/background-mode/ngx';
import { Autostart } from "@ionic-native/autostart/ngx";
import { AndroidFullScreen } from "@ionic-native/android-full-screen/ngx";
@Component({
	selector: "app-root",
	templateUrl: "app.component.html",
	styleUrls: ["app.component.scss"],
})
export class AppComponent {
	constructor(
		private platform: Platform,
		private splashScreen: SplashScreen,
		private statusBar: StatusBar,
		private autostart: Autostart,
		private androidFullScreen: AndroidFullScreen
	) {
		this.initializeApp();
		this.autostart.enable();
		// this.backgroundMode.isScreenOff(function(){
		//     console.log('SE APAGO PANTALLA')
		// })
		// this.backgroundMode.isActive().valueOf;
	}

	initializeApp() {
		window.localStorage.setItem("idioma", "esp");
		window.localStorage.setItem(
			"BQTToken",
			"83c9794a-9887-11eb-a8b3-0242ac130003"
		);
		this.platform.ready().then(() => {
			// this.statusBar.styleDefault();
			// this.splashScreen.hide();
			// this.androidFullScreen
			// 	.isImmersiveModeSupported()
			// 	.then(() => {
			// 		console.log("Immersive mode supported");
			// 		this.androidFullScreen.immersiveMode();
			// 	})
			// 	.catch((err) => console.log(err));
		});
	}
	ngOnInit() {
		// this.nav.push(FirstRunPage);
	}
	screenOff() {}
}
