import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
// import { File } from "@ionic-na  tive/file";
import { Platform } from '@ionic/angular';
import { sha256 } from "js-sha256";
import { Storage } from '@ionic/storage';



@Injectable()
export class BqDataStorageProvider {
    private _baseDir: string;
    private _fsInit: boolean;

    constructor(
        public http: HttpClient,
        // private _file: File,
        platform: Platform,
        private storage: Storage
    ) {
        this._baseDir = "datastorage";
        console.log("Starting data storage directory: %s", this._baseDir);
        this._fsInit = false;

        platform.ready().then(() => {
            // this._file
                // .checkDir(_file.externalDataDirectory, this._baseDir)
                // .then(dir => {
                //     this._fsInit = true;
                //     this.log("Storage directory exists!");
                // })
                // .catch(dirErr => {
                //     if (dirErr.code === 1) {
                //         this._file
                //             .createDir(
                //                 _file.externalDataDirectory,
                //                 this._baseDir,
                //                 false
                //             )
                //             .then(dirRes => {
                //                 this.log("Storage directory has been created!");
                //                 this._fsInit = true;
                //             })
                //             .catch(ndirErr => {
                //                 this.log(
                //                     "Cannot create storage directory, fs disabled!"
                //                 );
                //             });
                //     } else {
                //         this.log(
                //             "Unknown error code (" +
                //                 dirErr.code +
                //                 ") accessing storage directory, fs disabled!"
                //         );
                //     }
                // });
        });
    }

    // private log(text: string) {
    //     console.log("[BQDataStorage] " + text);
    // }

    // private generateFilename(varname: string) {
    //     let fName = "BQD" + sha256(varname);

    //     return fName;
    // }

    // isFSActive() {
    //     return this._fsInit;
    // }

    get(key: string) {
        console.log(' buscar en local' + key);
        this.storage.get(key).then(res=>{
            console.log(res);
            return res;
        })
        // let val = window.localStorage.getItem("BQDataStorage::" + key);
        // console.log("Requesting variable " + key + ": " + val);
    }

    set(key: string, value: any) {
        this.storage.set(key , value);
        return;
    }

    getFS(key: string) {
        var promise = new Promise((resolve, reject) => {
          this.storage.get(key).then(deviceId =>{
            console.log(deviceId);
            resolve(deviceId);
          })
          .catch(err =>{
              console.log("err " +err);
              reject(err);
          })
        });

        return promise;
    }

    setFS(key: string, value: any) {
    //     var promise = new Promise((resolve, reject) => {
    //         if (!this._fsInit) {
    //             reject("DataStorage filesystem not initialized!");
    //             return;
    //         }

    //         let fileName = this.generateFilename(key);
    //         console.log(
    //             this._file.externalDataDirectory + this._baseDir + "/" + fileName
    //         );
    //         this._file
    //             .checkFile(this._file.externalDataDirectory + this._baseDir, fileName)
    //             .then(() => {
    //                 this.log("Rewriting file");
    //                 this._file
    //                     .writeExistingFile(
    //                         this._file.externalDataDirectory + this._baseDir,
    //                         fileName,
    //                         encodeURI(
    //                             JSON.stringify({
    //                                 key: key,
    //                                 value: value
    //                             })
    //                         )
    //                     )
    //                     .then(wRes => {
    //                         resolve(value);
    //                     })
    //                     .catch(wErr => {
    //                         reject(wErr);
    //                     });
    //             })
    //             .catch(fErr => {
    //                 this.log("File not exists, creating file!");
    //                 this._file
    //                     .writeFile(
    //                         this._file.externalDataDirectory + this._baseDir,
    //                         fileName,
    //                         encodeURI(
    //                             JSON.stringify({ key: key, value: value })
    //                         ),
    //                         { replace: true }
    //                     )
    //                     .then(file => {
    //                         file.createWriter(
    //                             fileWriter => {

    //                                 fileWriter.onwriteend = e => {
    //                                     console.log("Write completed.");
    //                                     resolve(value);
    //                                 };
    //                                 fileWriter.write(
    //                                     encodeURI(
    //                                         JSON.stringify({
    //                                             key: key,
    //                                             value: value
    //                                         })
    //                                     )
    //                                 );
    //                             },
    //                             () => {
    //                                 alert("Unable to save file in path " + this._file.externalDataDirectory + this._baseDir);
    //                             }
    //                         );


    //                     })
    //                     .catch(wErr => {
    //                         this.log("Error creating variable file!");
    //                         console.dir(wErr);
    //                         reject(wErr);
    //                     });
    //             });
    //     });

    //     return promise;
    }
}
